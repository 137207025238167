.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.outer_container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.first_half,
.second_half {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  /* This ensures both halves take up 50% of the width */
}

.first_half img {
  max-width: 100%;
  /* width: 232.3px; */
  /* height: 231.21px; */
}

.appheader_menu_dropdown {
  border: none !important;
}

.inner_second_half {
  width: 80%;
  /* Adjust as needed for the inner content */
  text-align: left;
  /* Center the text inside the second half */
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.first_half {
  background: #595a5c !important;
}

.heading_log {
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
}

.label_custom {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
}

/* input {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  background: #58595b66;
  border: 1px solid #58595b2b;
} */

.second_half {
  font-family: "Work Sans", sans-serif;
  background: #f3f3f3;
}

.forgot_link_outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.forgot_pass_link {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  text-decoration: underline;
}

.signIn_btn {
  width: 100%;
  height: 48px;
  top: 439px;
  left: 779px;
  padding: 15px 0px 14px 0px;
  gap: 10px;
  border-radius: 8px 0px 0px 0px;
  background: #595a5c !important;
  color: #ffff !important;
}

.form_custom {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.firstInnerOr {
  height: 1px;
  background: #000;
  width: 100px;
}

.outerOR {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.outer_sidebar {
  background: #595a5c !important;
  height: 100vh;
  /* width: 240px !important; */
  /* max-width: 225px !important; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.sidebar-nav {
  width: 100%;
  padding-right: 0px !important;
  padding-left: 18px !important;
}

.outer_sidebar .sidebar-nav .nav-link {
  display: block !important;
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #ffff;
  width: 100%;
  justify-content: center;
  border-radius: 40px 0px 0px 40px;
  padding: 1rem;
  /* padding-right: 2rem !important; */
  padding-left: 27% !important;
}

.outer_sidebar .sidebar-nav .nav-link:hover {
  background-color: #6a6b6e;
  border-radius: 40px 0px 0px 40px;
}

.outer_sidebar .sidebar-nav .nav-item {
  width: 100%;
}

.sidebar_items {
  /* width: 100px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
}

.sideBar_icons {
  font-size: 24px !important;
}

.outer_sidebar .sidebar-nav .nav-link.active {
  background: #ffffff !important;
}

.main_container {
  display: flex;
}

@media (max-width: 991.98px) {
  .sidebar:not(.sidebar-end):not(.show) {
    margin-left: 0 !important;
  }
}

.outer_main_container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}

/* .table-container-customized {
  height: 63vh;
  overflow-y: scroll;
} */

.main_home_outer {
  min-height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  background: #f3f3f3;
  border-radius: 10px 10px 10px 10px;
}

.search_text {
  border: 1px solid #00000026;
  width: 298px;
  height: 38px;
  border-radius: 50px 50px 50px 50px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
  background: #ffff;
  padding: 18px;
}

.header_main_container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  background: #f3f3f3;
  padding: 1rem;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0.5px solid #00000033;
}

.outer_main_container {
  padding: 20px !important;
}

.coffee {
  border: 1px solid #595a5c !important;
  padding: 4px 8px 4px 8px;
  border-radius: 8px 8px 8px 8px;
  background: #fff;
  cursor: pointer;
  /* width: 130px !important; */
}

.categories-cont {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
}

.productsContainer {
  display: flex !important;
  align-items: center;
  justify-content: space-around;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.category {
  width: 100%;
  padding: 26px 0px 0px 0px;
}

.inner_category {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
}

.card_main {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
}

.card_img {
  width: 100%;
  height: 110px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 8px;
}
.profile_img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 8px;
}

.card_para {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.card_heading {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.card_p {
  font-size: 12px;
  margin: 2px 0 0;
  color: #000;
}

.card_pricing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_price {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.card_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.cards_container {
  display: flex;
  /* grid-template-columns: repeat(5, 19.5%); */
  /* gap: 8px; */
  padding: 10px;
  /* width: 100%; */
  box-sizing: border-box;
  flex-wrap: wrap;
  /* margin: auto; */
  align-items: center;
  /* justify-content: center; */
}

.modal-title {
  font-family: "Work Sans", sans-serif;
  font-size: 22px;
  font-weight: 600 !important;
  line-height: 26.4px;
  text-align: left;
  -webkit-font-smoothing: subpixel-antialiased;
}

.modal_title {
  font-family: "Work Sans", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: left;
  -webkit-font-smoothing: subpixel-antialiased;
}

.add_toCart_modal_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.price_in_modal {
  font-family: "Work Sans", sans-serif !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  line-height: 26.4px !important;
  text-align: left !important;
  color: #000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
}

.modal_custom_text {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 20px;
  text-align: left;
}

.custom_select_modal {
  width: 100% !important; /* Makes the select take up full width */
  max-width: 100%; /* Ensures it doesn't exceed the available width */
}

.custom_dropdown_modal__control {
  width: 100% !important; /* Ensures the select control takes full width */
}

.custom_dropdown_modal__menu {
  width: 100% !important; /* Ensures the dropdown menu also takes full width */
}
/* Radio button container */
.modal_radio_buttons {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
}

/* Style for the radio label */
.modal_radio_buttons label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 1.2px solid #d8d8d866;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
  white-space: nowrap;
  height: 40px;
}

/* Hover effect for labels */
.modal_radio_buttons label:hover {
  background-color: #f0f0f0;
}

.modal_radio_buttons input[type="radio"]:checked + label {
  background-color: #000;
  color: #fff;
  border-radius: 6px;
}

.modal_radio_buttons input[type="radio"] {
  display: none;
}

.modal_radio_buttons input[type="radio"]:checked {
  z-index: 100;
}

@media (max-width: 768px) {
  .modal_radio_buttons {
    flex-direction: column;
  }
  .modal_radio_buttons label {
    width: 100%;
    box-sizing: border-box;
  }
}
.add_toCart_modal_div .form-check {
  padding-left: 0px !important;
}
.add_toCart_modal_div .form-check-inline {
  margin-right: 5px !important;
}
.coffee_size_modal {
  display: flex;
  align-items: baseline;
  gap: 12px;
  justify-content: center;
}

.specialInstructions {
  font-family: "Work Sans", sans-serif !important;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.4px;
  text-align: left;
}

.inst_opt {
  color: #ff3030;
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
}

.addToCartModal {
  background: #f3f3f3 !important;
}

.addToCart_button {
  width: 239px;
  height: 48px;
  top: 585px;
  left: 564px;
  padding: 15px 49px 14px 49px;
  gap: 10px;
  border-radius: 8px 0px 0px 0px;
  /* opacity: 0px; */
  background: #595a5c !important;
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: center;
  border: none !important;
}

.addtoCart_input {
  width: 547px;
  height: 50px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
  border: 1px solid #58595b2b;
  background: #fff !important;
}

.profile_bg {
  background-color: #f3f3f3 !important;
  /* height: 85vh !important; */
}

.profile_name {
  font-family: "Work Sans", sans-serif !important;
  font-size: 22px;
  font-weight: 600;
  /* line-height: 26.4px; */
  text-align: left;
}

.profile_pic {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 32px;
}

.profile_photo {
  position: relative;
  display: inline-block;
}

.image_profile_cont {
  position: relative;
}

.profile_edit_outer {
  position: absolute;
  bottom: 0;
  right: 40px;
  width: 36px;
  height: 36px;
  background: #595a5c !important;
  border-radius: 50%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.profile_edit_label {
  margin-top: 5px;
}

.profile_edit {
  color: #fff;
  font-size: 24px !important;
}

.profile_data {
  display: flex;
  align-items: center;
  padding-top: 4rem;
  flex-wrap: wrap;
  gap: 20px;
}

.textt {
  font-size: 18px;
  font-weight: 500;
}

.profile_tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.padding_0 {
  padding: 0 !important;
}

.sidebar_custom_header {
  justify-content: center !important;
  padding: 0.8rem !important;
}

.home_table_tabs .nav-link {
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  /* line-height: 19.2px; */
  text-align: left;
  color: #000000 !important;
  cursor: pointer !important;
}

.home_table_tabs .nav-tabs .nav-link.active {
  background: transparent !important;
  border: none !important;
  border-bottom: 2px solid !important;
}

.home_table_tabs .nav-tabs .nav-link :hover {
  background: transparent !important;
  border: none !important;
}

.home_table_tabs .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none !important;
  isolation: auto !important;
}

.home_table_tabs .nav-link {
  transition: none !important;
}

.table-container {
  background-color: #f3f3f3;
  /* Update with your desired background color */
  padding: 20px;
  border-radius: 10px;
  /* Optional: add some padding */
}

.pagination_custom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
  background: #f3f3f3;
  border-radius: 0px 0px 10px 10px;
  margin-bottom: 0 !important;
}

.pagination_custom .page-item {
  margin: 0 5px;
}

.pagination_custom .page-item .page-link {
  cursor: pointer;
  color: #595a5c !important;
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  /* Background color */
  border: none;
  /* Border color */
  /* border-radius: 4px; */
  /* padding: 8px 12px; */
  text-decoration: none;
  /* transition: background-color 0.3s, border-color 0.3s, color 0.3s; */
}

.pagination_custom .page-item.active .page-link {
  border: 1px solid #595a5c !important;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_custom .page-item.disabled .page-link {
  color: #6c757d;
  /* Disabled text color */
  background-color: #e9ecef;
  /* Disabled background color */
  border-color: #dee2e6;
  /* Disabled border color */
  pointer-events: none;
}

.pagination_custom .page-item .page-link:hover {
  background-color: transparent;
  /* Hover background color */
  border-color: none;
  /* Hover border color */
}

.pagination_next_prev {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.category caption {
  font-family: "Work Sans", sans-serif !important;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.category th {
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
}

.category td {
  font-family: "Work Sans", sans-serif !important;
  font-size: 13px;
  font-weight: 500;
  /* line-height: 15.6px; */
  text-align: left;
}

.pending_badge {
  background: #fa97242b !important;
  width: Hug (81px) px;
  height: Hug (27px) px;
  top: 313px;
  left: 917px;
  padding: 5px 8px 5px 8px;
  gap: 5px;
  border-radius: 8px 8px 8px 8px;
  /* opacity: 0px; */
  color: #fa9724 !important;
}

.delivered_badge {
  background: #16d18c2b;
  width: Hug (81px) px;
  height: Hug (27px) px;
  top: 313px;
  left: 917px;
  padding: 5px 8px 5px 8px;
  gap: 5px;
  border-radius: 8px 0px 0px 0px;
  /* opacity: 0px; */
  color: #16d18c !important;
}

.cancel_badge {
  background: #ff00002b;
  width: Hug (81px) px;
  height: Hug (27px) px;
  top: 313px;
  left: 917px;
  padding: 5px 8px 5px 8px;
  gap: 5px;
  border-radius: 8px 0px 0px 0px;
  /* opacity: 0px; */
  color: #ff0000 !important;
}

.table_img {
  width: 41px;
  height: 41px;
  top: 306px;
  left: 330px;
  gap: 0px;
  border-radius: 8px 8px 8px 8px;
  /* opacity: 0px; */
}

.table_id {
  font-family: "Work Sans", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
}

/* ---------------------------------------------------------- */

.main_container {
  display: flex !important;
}

/* .outer_sidebar {
  padding: 0 !important; */
/* } */

/* .outer_main_container {
  padding: 0 !important;
} */

/* .main_home_outer {
  padding: 20px !important;
} */

.category {
  margin: 20px 0px 0px 0 !important;
}

.coffee.selected {
  border: 1px solid #595a5c !important;
  color: #fff !important;
  background: #595a5c !important;
}

.productsContainer {
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: center !important;
}

.category-title {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  /* margin-left: 1rem; */
}

.coffee {
  margin: 5px !important;
  width: 110px !important;
}

.inner_category {
  display: flex !important;
  color: #58595b;
  align-items: center !important;
}

.inner_category.selected {
  color: #fff;
}

.inner_category img {
  /* width: 30px !important; */
  /* height: 30px !important; */
}

@media (max-width: 992px) {
  .productsContainer {
    justify-content: center;
  }

  .coffee {
    flex: 1 0 30%;
    max-width: 30%;
  }
}

@media (max-width: 768px) {
  .coffee {
    flex: 1 0 45%;
    max-width: 45%;
  }
}

@media (max-width: 576px) {
  .coffee {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.customized-modal .modal-header {
  padding: 20px 30px 20px 30px;
}

.customized-modal .modal-body {
  padding: 20px 30px 20px 30px;
}

.customized-modal .modal-content {
  background: #f3f3f3;
  border-radius: 20px;
}

.offcanvas {
  border: 1.2px solid #d8d8d866 !important;
  width: 403px !important;
  background: #f3f3f3 !important;
  border-radius: 20px 0px 0px 20px !important;
}

.offcanvas-title {
  font-family: "Work Sans", sans-serif !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  line-height: 26.4px !important;
}

.offcanvas-header {
  justify-content: space-between !important;
}

.custom_offcanvas_outer {
  width: 300px;
}
.custom_offcanvas_outer {
  z-index: 1050 !important;
  display: block !important;
}
.offcanvas {
  visibility: visible !important;
  opacity: 1 !important;
  transform: none !important;
}

.item-container {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  top: 90px;
  border-radius: 8px 8px 8px 8px;
  margin-bottom: 1.5rem;
  gap: 12px;
}

.item-image {
  width: 93px;
  height: 69px;
  border-radius: 10px;
}
.unpaid-color {
  width: fit-content;
  background: #ff00002b;
  color: #ff0000;
  padding: 1px 10px;
  border-radius: 6px;
}
.paid-color {
  width: fit-content;
  background: #16d18c2b;
  color: #16d18c;
  padding: 1px 10px;
  border-radius: 6px;
}

.item-details {
  flex-grow: 1;
}

.item-text {
  font-family: "Work Sans", sans-serif !important;
  font-size: 15px;
  font-weight: 600;
  /* line-height: 16.8px; */
  /* text-align: le/ft; */
}

.item-description {
  font-family: "Work Sans", sans-serif !important;
  font-size: 11px;
  font-weight: 400;
  /* line-height: 13.2px; */
  /* text-align: left; */
  color: #757575;
}

.item-price {
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  /* line-height: 19.2px; */
  /* text-align: left; */
  color: #001833;
}

.total-price {
  font-family: "Work Sans", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  color: #00183338;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-price span {
  font-family: "Work Sans", sans-serif !important;
  font-size: 22px;
  font-weight: 600;
  color: #000000;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.add-more-button,
.checkout-button {
  flex: 1;
  margin: 5px;
}

.delete-action {
  width: 48px;
  height: 100%;
  background: #ffe5e5;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0rem 1rem;
}

.counter-container {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 5px 10px;
  background-color: #fff;
  width: 100px;
  width: 72.76px;
  height: 25px;
  border-radius: 6px 6px 6px 6px;
  gap: 8px;
}

.counter-button {
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

.counter-value {
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
}

.swipeable-list {
  height: 75vh !important;
}

.order_confirm_modal .modal-content {
  background: #f3f3f3 !important;
}

.add-more-button {
  background: #595a5c !important;
  gap: 10px;
  border-radius: 8px 8px 8px 8px !important;
  /* opacity: 0px; */
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  border: none !important;
}

/* .modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
} */

.card-radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 20px 0;
  background-color: #f9f9f9;
  height: 90px;
  border-radius: 10px 10px 10px 10px;
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #000;
}

.order-summary {
  width: 100%;
  /* padding: 10px 20px; */
}

.summary-row {
  display: flex;
  justify-content: space-between;
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
}

.total-row {
  font-weight: bold;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
}

.custom_radio .form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}

.custom_radio .form-check-label {
  padding-top: 3px;
}

.custom_radio_gender .form-check-label {
  padding-top: 0px;
}

.gender-label-profile {
  font-family: "Work Sans", sans-serif !important;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.total_price_order {
  font-family: "Work Sans", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
}

.total_price_order_amnt {
  font-family: "Work Sans", sans-serif !important;
  font-size: 22px;
  font-weight: 600;
  line-height: 26.4px;
  text-align: left;
}

.order_custom_modal .modal-content {
  width: 506px !important;
  min-height: 280px !important;
  border-radius: 20px 20px 20px 20px !important;
  background: #f3f3f3 !important;
}

.outer_order_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.order_pay_now {
  background: #595a5c !important;
  border: none !important;
  height: 48px;
  top: 565px;
  left: 623px;
  padding: 15px 75px 14px 74px;
  gap: 10px;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.order-success-modal .modal-content {
  width: 506px;
  height: 560px;
  border-radius: 20px 20px 20px 20px;
  background: #f3f3f3 !important;
}

.order-success-modal .modal-header {
  border-bottom: none;
}

.order-success-modal .modal-body {
  text-align: center;
  padding: 30px;
}

.order-success-modal .modal-body h5 {
  font-family: "Work Sans", sans-serif !important;
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: center;
}

.order-success-modal .modal-body p {
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.order-success-modal .modal-body .btn {
  width: 310px;
  height: 48px;
  padding: 15px 85px 14px 84px;
  gap: 10px;
  border-radius: 8px 8px 8px 8px;
  background: #595a5c !important;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
}

.order_sucess_button_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-success-img {
  width: 177px !important;
  height: 177px !important;
  padding: 5.53px 13.83px 5.53px 13.83px;
}

/* ---------------------------------  order Status ----------------------- */

.order-status-modal .modal-content {
  width: 506px;
  height: 560px;
  border-radius: 20px 20px 20px 20px;
  background: #f3f3f3;
}

.order-status-modal .modal-header {
  border-bottom: none;
}

.order-status-modal .modal-body {
  padding: 30px;
  padding-top: 5rem;
}

.order-status-modal .order-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 3rem; */
  /* color: #666; */
  width: 271px;
  height: 41px;
  padding: 12px 25px 12px 25px;
  gap: 10px;
  border-radius: 50px 50px 50px 50px;
  border: 1px 0px 0px 0px;
  border: 1px solid #00000047;
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #000;
}

.order-status-modal .order-steps {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.order-status-modal .order-step {
  /* display: flex; */
  /* align-items: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.order-status-modal .order-step .icon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #e9ecef;
  margin-right: 10px;
}

.order-status-modal .accepted-order-stats {
  color: #2bbf58;
  font-size: 26px;
}

.order-status-modal .accepted-order-pending {
  color: #ffd233;
  font-size: 26px;
}

.order-status-modal .accepted-order-cofffee {
  background-color: #6c757d;
  color: #fff;
}

.order-status-modal .order-step .time {
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
}

.order-status-modal .order-step .description {
  /* flex: 1; */
  width: 268px;
  height: 50px;
  border-radius: 12px 12px 12px 12px;
  background: #ffffff;
  padding: 20px;
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.order-status-modal .order-step.accepted .description {
  width: 268px;
  height: 50px;
  border-radius: 12px 12px 12px 12px;
  /* opacity: 0px; */
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  background: #595a5c !important;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.order-status-modal .outer_orderDetils {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

/* ----------------------- order details modal ------------------------------- */

.order-details-modal .modal-header {
  border-bottom: none;
  margin-bottom: 0.6rem;
}

.order-details-modal .modal-content {
  border-radius: 20px 20px 20px 20px;
  background: #f3f3f3;
}

.order-details-modal .modal-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.order-details-modal .modal-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-details-modal .order-info {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.order-details-modal .order-info img {
  width: 239px;
  height: 239px;
  border-radius: 8px 8px 8px 8px;
}

.order-details-modal .order-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.order-details-modal .order-details h5 {
  font-family: "Work Sans", sans-serif !important;
  font-size: 24px;
  font-weight: 600;
}

.action_order_details {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;
  padding-right: 3rem;
}

.order-details-modal .status .badge {
  padding: 0.5em 1em;
  border-radius: 12px;
}

.order-details-modal .order-summary {
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.order-details-id {
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
}

.order-details-inform {
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
}

.order-details-info-item {
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  width: 100px;
}

.order-details-info-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-details-status_ {
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
}

.action_order_details {
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
}

/* -------------------------profile modal css-------------------------- */

.profile-modal .modal-content {
  width: 795px;
  height: 553px;
  border-radius: 20px 20px 20px 20px;
  background: #f3f3f3;
}

.profile_name_email {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.profile-modal .modal-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.profile-modal .modal-body {
  padding: 20px;
}

.form-section {
  margin-bottom: 20px;
  text-align: left;
}

.form-section h6 {
  font-family: "Work Sans", sans-serif !important;
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: left;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  font-family: "Work Sans", sans-serif !important;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #000;
}

.form-group input {
  /* width: 400px; */
  height: 50px;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #58595b2b;
  background: #ffffff;
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.gender-profile {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 1rem;
}

.form-row {
  display: flex;
  gap: 15px;
}

.form-row .form-group {
  flex: 1;
}

.edit-icon {
  cursor: pointer;
}

/* -------------------------------------------subscriptions -------------------------------- */

.main_container {
  height: 100vh;
  overflow: hidden;
}

.outer_sidebar {
  background-color: #f8f9fa;
}

.outer_main_container {
  padding: 0;
  background-color: #fff;
}

.sub-title {
  font-family: "Work Sans", sans-serif !important;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #000;
}

.sub-description {
  font-family: "Work Sans", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  text-align: left;
}

.sub-view-all-btn {
  width: 114px !important;
  height: 26px !important;
  padding: 0 !important;
  border-radius: 50px 50px 50px 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: #595a5c !important;
  font-family: "Work Sans", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-align: left !important;
  color: #fff !important;
}

.profile_bg {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.subscription-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.subscription-card img {
  height: 200px;
  object-fit: cover;
}

.sub-heading {
  font-family: "Work Sans", sans-serif !important;
  font-size: 22px;
  font-weight: 600;
  color: #000;
  text-align: left !important;
}

.sub-breadcrumbs {
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #000;
}

.sub-add {
  border: 1px solid #595a5c !important;
  width: 119px !important;
  height: 33px !important;
  border-radius: 6px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  color: #595a5c !important;
}

.add-more-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* ------------------------------subscription modal -------------------------------- */
.sub-modal .modal-content {
  border-radius: 20px 20px 20px 20px !important;
  background: #f3f3f3 !important;
}

.subscription-option {
  padding: 20px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
}

.subscription-option.plus {
  border-radius: 8px 8px 8px 8px;
  background: #595a5c !important;
  color: #fff;
}

.subscription-option.collab {
  border-radius: 8px 8px 8px 8px;
  background: #fff;
  color: #000;
}

.subscription-option h5 {
  font-family: "Work Sans", sans-serif !important;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.subscription-option .upper-tier {
  font-family: "Work Sans", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 8px;
}

.subscription-option .mid-tier {
  font-family: "Work Sans", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 8px;
}

.modal-title {
  font-size: 1.5rem;
}

.get-sub-btn {
  width: 126px;
  height: 26px;
  border-radius: 50px 50px 50px 50px;
  font-family: "Work Sans", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  border: none;
}

.get-sub-btn-2 {
  width: 126px;
  height: 26px;
  border-radius: 50px 50px 50px 50px;
  font-family: "Work Sans", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
  border: none;
  background: #595a5c !important;
  color: #fff;
}

.outer-sub-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ------------------------------sub -plan -modal-------------------------------- */

.subPlan-modal .modal-content {
  width: 851px;
  height: 447px;
  border-radius: 20px 20px 20px 20px;
  background: #f3f3f3;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 500;
}

.subtitle {
  font-family: "Work Sans", sans-serif !important;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: #000;
}

.plan-container {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.plan-card {
  width: 353px;
  height: 147px;
  border-radius: 8px 8px 8px 8px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-content {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.plan-icon {
  width: 76.65px;
  height: 77px;
  top: 389px;
  left: 339px;
  gap: 0px;
  /* opacity: 0px; */
}

.plan-card h6 {
  font-family: "Work Sans", sans-serif !important;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #000;
}

.plan-card p {
  font-family: "Work Sans", sans-serif !important;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #000;
}

.buy-now-btn {
  width: 96px;
  height: 33px;
  border-radius: 50px 50px 50px 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: #595a5c !important;
  border: none !important;
  font-family: "Work Sans", sans-serif !important;
  font-weight: 500;
  text-align: center;
  font-size: 11px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.buy-now-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.sub-plan-dropdown {
  width: 127px;
  height: 33px;
  border: 1px solid #58595b2b;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-plan-dropdown .dropdown-toggle {
  white-space: nowrap;
  background: transparent;
  color: #000;
  border: none;
}

/* ----------------------------------------------------sub detail modal----------------------------------- */
.sub-detail-modal .modal-content {
  background: #f3f3f3 !important;
  border-radius: 20px 20px 20px 20px;
  height: 500px !important;
}

.review-detail-modal .modal-content {
  background: #f3f3f3 !important;
  border-radius: 20px 20px 20px 20px;
  min-width: 570px !important;
}

.review-read-data {
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.sub-detail-modal .modal-footer {
  border-top: none !important;
}

.sub-detail-checkout-btn {
  width: 100%;
  height: 48px;
  padding: 15px 27px 14px 27px;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #595a5c !important;
  color: #fff;
  border: none;
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
}

.plan-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.plan-image {
  width: 239px;
  height: 194px;
  left: 277px;
  border-radius: 8px 8px 8px 8px;
}

.plan-info {
  flex: 1;
}

.plan-info h5 {
  font-family: "Work Sans", sans-serif !important;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: #000;
}

.plan-info p {
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #000;
}

.subscriptions-title {
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #000 !important;
}

.subscriptions-title-1 {
  font-family: "Work Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #000 !important;
}

.subscriptions-products {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 12px;
}

.product-card {
  width: 143px;
  height: 149px;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: none !important;
}

.plan-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.product-card .card-title {
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #000;
}

.product-card .card-text {
  font-family: "Work Sans", sans-serif !important;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.2px;
  text-align: center;
}

.product-card .card-img-top {
  width: 88px;
  height: 65px;
}

.sub-detail-title-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sub-detail-badge {
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 6px !important;
}

.text-profile-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-family: "Work Sans", sans-serif !important;
  font-size: 18.17px;
  font-weight: 500;
}

.sub-success-text {
  font-family: "Work Sans", sans-serif !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  color: #000 !important;
  text-align: center;
}

.custom_dropdown_modal .btn:disabled {
  background: transparent;
  border: 1.2px solid #d8d8d866;
  color: #001833;
  font-weight: 500;
}

.custom_dropdown_modal {
  background-color: transparent;
  border: none;
}

.custom-profile-check {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.custom-pro-check-text {
  font-family: "Work Sans", sans-serif !important;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}

.tier {
  font-family: "Work Sans", sans-serif !important;
  color: #000000 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.tier-price {
  font-family: "Work Sans", sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  text-align: left !important;
}

.tier-outer {
  padding: 1rem;
  gap: 16px;
}

.subscription-option {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #ddd;
  /* Optional: for better visual separation */
  background-color: #fff;
  /* Optional: background color */
  border-radius: 8px;
  /* Optional: rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Optional: subtle shadow */
}

.outer-sub-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.tier-price {
  margin-right: 10px;
}

.subscription-option {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tier-price-inner {
  font-family: "Work Sans", sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: left !important;
}

/* ---------------------------------------------review us --------------------------------- */

.main_container {
  display: flex;
}

.outer_sidebar {
  padding: 0;
}

.outer_main_container {
  padding: 0 20px;
}

.main_home_outer {
  padding: 20px;
}

.profile_bg {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.sub-heading {
  font-size: 24px !important;
  /* font-weight: bold; */
}

.sub-breadcrumbs {
  color: #666;
}

.total-reviews {
  font-size: 18px;
  font-weight: 400;
  background-color: #666;
  color: #fff;
  padding: 4px 15px;
  border-radius: 8px;
}

.total-rev {
  font-family: "Work Sans", sans-serif !important;
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.review-count {
  font-size: 24px;
  margin-left: 10px;
}

.review-card {
  border: 1px solid #ddd !important;
  border-radius: 15px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-card .card-body {
  padding: 20px;
}

.review-card .card-title {
  font-family: "Work Sans", sans-serif !important;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  /* justify-content: space-between; */
  gap: 0.8rem;
  align-items: center;
}

.review-card .stars {
  color: #ffcc00;
  font-size: 18px;
}

.review-card .card-text {
  font-size: 16px;
  color: #333;
  text-align: left;
}

.review-card .card-text a {
  color: #007bff;
  text-decoration: none;
}

.review-card .card-text a:hover {
  text-decoration: underline;
}

.readmore {
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  cursor: pointer;
}

.stars {
  color: #ffcc00;
  font-size: 18px;
}

/* -----------------------------------------------------loader css ----------------------------------- */

/* src/Components/Loader.css */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.home_list_dropdownToggle.dropdown-toggle::after {
  display: none !important;
}
.home_list_dropdown.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border: none !important;
}

.notification {
  cursor: pointer;
  position: relative;
}

.background-notification-count {
  position: absolute;
  top: -5px;
  border-radius: 50%;
  /* padding: 3px; */
  display: flex;
  right: -4px;
  background-color: rgb(255, 0, 0);
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
}

.background-notification-count-cart {
  position: absolute;
  top: -5px;
  border-radius: 50%;
  /* padding: 3px; */
  display: flex;
  right: -4px;
  background-color: #1b9e3e;
  height: 18px;
  width: 18px;
  justify-content: center;
  align-items: center;
}
.background-notification-count-inner {
  color: #fff;
  font-size: 10px;
  font-weight: 700;
}

.buyer_img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

/* stripe CSS */
.card-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
}

.card-input {
  margin-top: 10px;
}

._failed {
  border-bottom: solid 4px red !important;
}
._failed i {
  color: red !important;
}

._success {
  box-shadow: 0 15px 25px #00000019;
  padding: 45px;
  /* width: 100%; */
  text-align: center;
  margin: 40px auto;
  border-bottom: solid 4px #28a745;
}

._success i {
  font-size: 55px;
  color: #28a745;
}

._success h2 {
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

._success p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #495057;
  font-weight: 500;
}

.outer-suceess-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-text {
  font-family: "Work Sans", sans-serif !important;
  font-size: 13px;
  font-weight: 500;
}

.gobackHome {
  color: #fff !important;
  font-weight: 500 !important;
  margin-top: 1rem !important;
}

.success-body {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;
  height: 100vh;
}

.success-card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}

.success-icon-container {
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background: #f8faf5;
  margin: 0 auto;
}

.success-checkmark {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}

.success-heading {
  color: #88b04b;
  font-family: "Work Sans", sans-serif !important;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.success-message {
  color: #404f5e;
  font-family: "Work Sans", sans-serif !important;
  font-size: 20px;
  margin: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sidebar-logo-img {
  height: 45px;
  width: 210px;
}
